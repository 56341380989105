import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownButton,
    Button,
} from "react-bootstrap";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function AddSuperAdmin(props) {
  const navigate = useNavigate();
  const params = useParams();
  const [name, setName] = useState("");
  const [newNumber, setNewNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const[email, setEmail] = useState("");

  const handleAddNumber = async (e) => {
    e.preventDefault();
    if (name !== '' && newNumber !== '' && newNumber.length === 10 && email != "") {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/admin/customer/admin_auth_otp`
        );

        if (res.data.status === 'success') {
          setIsAdd(true);
          toast.success(
            `OTP sent successfully to Super Admin.`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
        else {
          toast.error(
            `Failed to send the OTP`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    } else {
      toast.error(
        `Please Enter Correct Mobile Number and Name`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  }

  const handleOtpSubmit =async(e)=>{
    e.preventDefault();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/add_admin_number`, {
        full_name: name,
        phone: newNumber,
        otp:otp,
        email,
      }
      );

      if (res.data.status === 'success') {
        toast.success(
          `New number added successfully.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setTimeout(() => {
          navigate(`/user/superadmin`);
        }, 3100);

      }else{
        toast.error(
          `${res.data.message}`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const onOtpFields = (field_name, value) => {
    if (field_name === 'otp') {
        setOtp(value);
    } else if (field_name === 'new_number') {
        setNewNumber(value);
    } else if (field_name === 'name') {
        setName(value)
    } else if(field_name === 'email'){
      setEmail(value)
    }

  };

  return (
    <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                paddingTop: "95px",
                backgroundColor: "#F1F5F7",
            }}
        >
            <div className="row d-flex justify-content-center">
                <div className="form-container">
                    <div className="form-head d-flex">
                        <div>OTP Verification</div>
                        <div className="ml-auto" style={{ marginLeft: "auto" }}>
                            <Button
                                onClick={() => {
                                    navigate(-1);
                                }}
                                type="primary"
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                    <div className="form-body">
                        <form onSubmit={handleOtpSubmit}>
                            {
                                <>
                                    <Row>
                                        <Col>
                                            {" "}
                                            <div className="mb-3 p-2">
                                                <label className="form-label">Enter Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    value={name}
                                                    onChange={(e) =>
                                                        onOtpFields("name", e.target.value)
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            {" "}
                                            <div className="mb-3 p-2">
                                                <label className="form-label">Enter New Number</label>
                                                <input
                                                    maxlength="10"
                                                    inputmode="numeric"
                                                    pattern="\d*"
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    value={newNumber}
                                                    onChange={(e) =>
                                                        onOtpFields("new_number", e.target.value)
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                            <div className="mb-3 p-2">
                                                <label className="form-label">Enter Email</label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    required
                                                    value={email}
                                                    onChange={(e) =>
                                                        onOtpFields("email", e.target.value)
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {(isAdd === false) &&
                                        <div className="p-2">
                                            <button className="btn btn-primary" onClick={handleAddNumber}>
                                                Add
                                            </button>
                                        </div>
                                    }
                                </>
                            }
                            {(isAdd === true) &&
                                <>
                                    <Row>
                                        <Col>
                                            {" "}
                                            <div className="mb-3 p-2">
                                                <label className="form-label">Enter OTP</label>
                                                <input
                                                    maxlength="6"
                                                    inputmode="numeric"
                                                    pattern="\d*"
                                                    type="text"
                                                    className="form-control"
                                                    required
                                                    onChange={(e) =>
                                                        onOtpFields("otp", e.target.value)
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="p-2 d-flex justify-content-between">
                                        <button className="btn btn-primary  " type="submit">
                                            Submit
                                        </button>
                                        <div className='btn btn-warning' onClick={handleAddNumber}>
                                          Resend
                                        </div>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>

        </Container>
  )
}

export default AddSuperAdmin
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownButton,
  Button,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSingleCustomer,
  updateCustomerData,
} from "../../store/actionCreators/Customers/CustomerAction";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { getAllCustomerRoles } from "./../../store/actionCreators/Customers/CustomerRolesActions";
import { toast } from "react-toastify";
import axios from "axios";

const EditCustomer = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const customer = useSelector((state) => state.single_customer);
  const customer_roles = useSelector((state) => state.customer_roles);

  const [data, setData] = useState({});
  const [changeCustomerType, setChangeCustomerType] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [otp, setOtp] = useState("");
  const [optMobile, setOptMobile] = useState({});
  const [newNumber, setNewNumber] = useState("");
  const [name, setName] = useState("");
  const [admiNumber, setAdminNumber] = useState([]);
  const [isWalletVerified, setIsWalletVerified] = useState(false);
  const [isWalletOtp, setIsWalletOtp] = useState(false);
  const[email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState();

  useEffect(() => {
    dispatch(fetchSingleCustomer(params.customer_no));
    dispatch(getAllCustomerRoles());
  }, []);

  useEffect(() => {
    setDefaultData();
  }, [customer]);

  const navigate = useNavigate();

  const setDefaultData = () => {
    if (customer.data && customer.data.status === "success") {
      setData(customer.data.data);
    }
  };

  const onChangeFields = (field_name, value) => {
    const dataClone = { ...data };
    dataClone[field_name] = value;
    setData(dataClone);
  };

  const onOtpFields = (field_name, value) => {
    if(field_name === 'otp'){
      setOtp(value);
    }else if(field_name === 'new_number'){
      setNewNumber(value);
    }else if(field_name === 'name'){
      setName(value)
    }else if(field_name === 'email'){
      setEmail(value)
    }
    
  };

  const add_new_number =async()=>{
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/add_admin_number`, {
        full_name: name,
        phone: newNumber,
        otp:otp,
        email
      }
      );

      if (res.data.status === 'success') {
        setOtp("")
        toast.success(
          `New number added successfully.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setTimeout(() => {
          navigate(`/customer/${data.mobile_no}`);
        }, 3100);

      }else{
        toast.error(
          `${res.data.message}`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const sendOtp = async()=>{
    setIsAdd(false)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/send_otp_admin`,{
          phone: optMobile?.value || mobileNo
        }
      );

      if (res.data.status === "success") {
        setMobileNo(optMobile?.value)
        toast.success(
          `OTP sent successfully`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
      else{
        toast.error(
          `Failed to send OTP.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const verifyOtp = async()=>{
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/verify_admin_otp`, {
        phone: optMobile?.value,
        OTP:otp
      }
      );

      if (res.data.status === 'success') {
        // setOptMobile({})
        setOtp("")
        toast.success(
          `OTP verified successfully.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setIsVerified(true); 
        
      }else{
        toast.error(
          `${res.data.msg}`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const handleOtpSubmit = async(e)=>{
    e.preventDefault();
    if(optMobile.value === 'Add'){
      add_new_number()
    }
    else{
      verifyOtp()
    }
  }


  useEffect(()=>{
    if(optMobile?.value && optMobile?.value !== 'Add'){
      sendOtp()
    }
  },[optMobile?.value])

  const mobile_no_arr = [
    {
      label:"Add New Number",
      value:"Add"
    }
  ]

  const handleAddNumber = async(e)=>{
    e.preventDefault();
    if(name !== '' && newNumber !== '' && newNumber.length === 10 && email !== ""){
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/admin/customer/admin_auth_otp`
        );

        if (res.data.status === 'success') {
          setIsAdd(true);
          toast.success(
            `OTP sent successfully to Super Admin.`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
        else{
          toast.error(
            `Failed to send the OTP`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    }else{
      toast.error(
        `Please Enter Correct Mobile Number and Name`,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    }
  }

  const sendSuperAdminOtp = async()=>{
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/send_otp_admin`,{
          phone: mobileNo
        }
      );

      if (res.data.status === "success") {
        setIsWalletVerified(true)
        toast.success(
          `OTP sent successfully`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
      else{
        toast.error(
          `Failed to send OTP.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const verifySuperAdminOtp = async()=>{
    console.log(otp)
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/verify_admin_otp`, {
        phone: mobileNo,
        OTP:otp
      }
      );

      if (res.data.status === 'success') {
        setIsWalletOtp(true);
        setOtp("")
        toast.success(
          `OTP verified successfully.`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setIsVerified(true); 
        
      }else{
        toast.error(
          `${res.data.msg}`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const fetch_admin_number = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}api/v1/admin/customer/fetch_admin_number`
      );

      if (res.data.status === "success") {
        setAdminNumber(res?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(()=>{
    fetch_admin_number();
  },[])

  useEffect(()=>{
    if(isWalletVerified){

    }
  },[isWalletVerified])

  useEffect(() => {
    for(let i = 0; i < admiNumber.length; i++){
      let option = {
        label: `${admiNumber[i].full_name}(${admiNumber[i].phone})`,
        value: admiNumber[i].phone
      }
      mobile_no_arr.unshift(option)
    }
  }, [admiNumber, mobile_no_arr]);

  const handleEditCustomer = async (e) => {
    e.preventDefault();
    const {
      customer_id,
      first_name,
      last_name,
      mobile_no,
      email,
      date_of_birth,
      gender,
      branch,
      branch_id,
      customer_type,
      shipping_address,
      password,
      wallet_balance,
      memb_upg_amount,
      memb_reduce_amount,
      add_from,
      total_spend,
      billing_address,
      perma_cat,
    } = data;

    const body = {
      customer_id,
      first_name,
      last_name,
      mobile_no,
      email,
      date_of_birth,
      gender,
      branch,
      branch_id,
      shipping_address,
      wallet_balance: parseFloat(wallet_balance),
      memb_upg_amount: parseFloat(memb_upg_amount),
      memb_reduce_amount: parseFloat(memb_reduce_amount),
      add_from,
      total_spend: parseFloat(total_spend),
      billing_address,
      perma_cat,
    };

    if (changePassword) {
      if (!data.password) {
        toast.error(
          `Please enter password or uncheck the change password field`,
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      } else {
        body.password = password;
      }
    }

    if (changeCustomerType) {
      body.customer_type = customer_type;
    }

 dispatch(updateCustomerData(body))
   .then((response) => {
     if(response.status==="success") {
      setTimeout(()=>{ navigate("/customer/allCustomer");},3000);     }
   })
   .catch((error) => {
     console.error('Error:', error); 
   });
   
  };
  if(isVerified){
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
      >
        <div className="row d-flex justify-content-center">
          <div className="form-container">
            <div className="form-head d-flex">
              <div>Edit Customer data</div>
              <div className="ml-auto" style={{ marginLeft: "auto" }}>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="primary"
                >
                  Back
                </Button>
              </div>
            </div>
            <div className="form-body">
              <form onSubmit={handleEditCustomer}>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={data.first_name ? data.first_name : ""}
                        onChange={(e) =>
                          onChangeFields("first_name", e.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={data.last_name ? data.last_name : ""}
                        onChange={(e) =>
                          onChangeFields("last_name", e.target.value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        required
                        value={data.email ? data.email : ""}
                        onChange={(e) => onChangeFields("email", e.target.value)}
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Mobile Number</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        value={data.mobile_no ? data.mobile_no : ""}
                        onChange={(e) =>
                          onChangeFields("mobile_no", e.target.value)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Date Of Birth</label>
                      <input
                        type="date"
                        className="form-control"
                        required
                        value={data.date_of_birth ? data.date_of_birth : ""}
                        onChange={(e) =>
                          onChangeFields("date_of_birth", e.target.value)
                        }
                      />
                    </div>
                  </Col>
  
                  <Col>
                    {" "}
                    <div className="mb-3 p-2 ">
                      <label className="form-label">Branch</label>
                      <div className="">
                        <Select
                          options={props.branchArray.map((b) => ({
                            label: b.branch_name,
                            value: b.branch_id,
                          }))}
                          value={{
                            label: `${props.branchObj[data.branch_id]}`,
                            value: data.branch_id,
                          }}
                          onChange={(e) => onChangeFields("branch_id", e.value)}
                        />
                      </div>
                    </div>
                  </Col>
  
                  <Col>
                    <div className="mb-3 p-2 ">
                      <label className="form-label">Change Customer Type ?</label>
                      <div className="">
                        <input
                          type="checkbox"
                          id="customer_type"
                          checked={changeCustomerType}
                          onChange={() =>
                            setChangeCustomerType(!changeCustomerType)
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2 ">
                      <label className="form-label">Customer Type</label>
                      <div className="">
                        <Select
                          options={
                            customer_roles.data
                              ? customer_roles.data.status === "success"
                                ? customer_roles.data.all_customer_roles.map(
                                    (cr) => ({
                                      label: cr.customer_type,
                                      value: cr.customer_type,
                                    })
                                  )
                                : []
                              : []
                          }
                          onChange={(e) =>
                            onChangeFields("customer_type", e.value)
                          }
                          value={{
                            label: data.customer_type,
                            value: data.customer_type,
                          }}
                          isDisabled={!changeCustomerType}
                        />
                      </div>
                    </div>
                  </Col>
  
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Gender</label>
                      <div className="d-flex">
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={data.gender === "male"}
                            onChange={() => onChangeFields("gender", "male")}
                          />
                          <label className="form-check-label">Male</label>
                        </div>
                        <div className="form-check ">
                          <input
                            className="form-check-input"
                            type="radio"
                            checked={data.gender === "female"}
                            onChange={() => onChangeFields("gender", "female")}
                          />
                          <label className="form-check-label">Female</label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Wallet Balance</label>
                      {isWalletOtp &&
                        <input
                          type="number"
                          className="form-control"
                          aria-describedby="walletBalance"
                          value={data.wallet_balance ? data.wallet_balance : ""}
                          onChange={(e) =>
                            onChangeFields("wallet_balance", e.target.value)
                          }
                        />
                      }
                      {!isWalletVerified &&
                        <div className="btn btn-primary" onClick={sendSuperAdminOtp}>
                          Send Otp
                        </div>
                      }
                      {(isWalletVerified && !isWalletOtp) &&
                        <div>
                          <div className="mb-3 p-2">
                            <label className="form-label">Enter OTP</label>
                            <input
                              maxlength="6"
                              inputmode="numeric"
                              pattern="\d*"
                              type="text"
                              className="form-control"
                              required
                              onChange={(e) =>
                                onOtpFields("otp", e.target.value)
                              }
                            />
                          </div>
                          <div className="d-flex gap-3">

                          <div className="btn btn-primary" onClick={verifySuperAdminOtp}>
                            Submit
                          </div>
                          <div className="btn btn-warning" onClick={sendSuperAdminOtp}>Resend</div>
                          </div>
                        </div>
                      }
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Lifetime Spend</label>
                      <input
                        type="number"
                        className="form-control"
                        aria-describedby="walletBalance"
                        value={data.total_spend ? data.total_spend : ""}
                        onChange={(e) =>
                          onChangeFields("total_spend", e.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">
                        Membership Upgrade Amount
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        aria-describedby="walletBalance"
                        value={data.memb_upg_amount ? data.memb_upg_amount : ""}
                        onChange={(e) =>
                          onChangeFields("memb_upg_amount", e.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">
                        Membership Retention Amount
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        aria-describedby="walletBalance"
                        value={
                          data.memb_reduce_amount ? data.memb_reduce_amount : ""
                        }
                        onChange={(e) =>
                          onChangeFields("memb_reduce_amount", e.target.value)
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Add From</label>
                      <Select
                        onChange={(e) => onChangeFields("add_from", e.value)}
                        value={{ label: data.add_from, value: data.add_from }}
                        options={[
                          { label: "APP", value: "APP" },
                          { label: "WEB", value: "WEB" },
                          { label: "POS", value: "POS" },
                          { label: "Admin", value: "Admin" },
                        ]}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Billing Address</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={
                          data.billing_address
                            ? data.billing_address.address
                              ? data.billing_address.address
                              : ""
                            : ""
                        }
                        onChange={(e) =>
                          onChangeFields("billing_address", {
                            ...data.billing_address,
                            address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">
                        Billing Address Pincode
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={
                          data.billing_address
                            ? data.billing_address.pincode
                              ? data.billing_address.pincode
                              : ""
                            : ""
                        }
                        onChange={(e) =>
                          onChangeFields("billing_address", {
                            ...data.billing_address,
                            pincode: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Shipping Address</label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        value={
                          data.shipping_address
                            ? data.shipping_address.address
                              ? data.shipping_address.address
                              : ""
                            : ""
                        }
                        onChange={(e) =>
                          onChangeFields("shipping_address", {
                            ...data.shipping_address,
                            address: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
  
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">
                        Shipping Address Pincode
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        aria-describedby="emailHelp"
                        onChange={(e) =>
                          onChangeFields("shipping_address", {
                            ...data.shipping_address,
                            pincode: e.target.value,
                          })
                        }
                        value={
                          data.shipping_address
                            ? data.shipping_address.pincode
                              ? data.shipping_address.pincode
                              : ""
                            : ""
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="mb-3 p-2 ">
                      <label className="form-label">Change Password ?</label>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          id="password"
                          checked={changePassword}
                          onChange={() => setChangePassword(!changePassword)}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2 ">
                      <label className="form-label">Password</label>
                      <div className="d-flex">
                        <input
                          type="password"
                          className="form-control"
                          disabled={!changePassword}
                          onChange={(e) =>
                            onChangeFields("password", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2 ">
                      <label className="form-label">Permanent Category</label>
                      <div className="d-flex">
                        <input
                          type="checkbox"
                          checked={data.perma_cat ? true : false}
                          onChange={() =>
                            onChangeFields("perma_cat", !data.perma_cat)
                          }
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="p-2">
                  <button className="btn btn-primary  " type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Container>
    );
  }else{
    return (
      <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          paddingTop: "95px",
          backgroundColor: "#F1F5F7",
        }}
      >
        <div className="row d-flex justify-content-center">
          <div className="form-container">
            <div className="form-head d-flex">
              <div>OTP Verification</div>
              <div className="ml-auto" style={{ marginLeft: "auto" }}>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="primary"
                >
                  Back
                </Button>
              </div>
            </div>
            <div className="form-body">
              <form onSubmit={handleOtpSubmit}>
                <Row>
                  <Col>
                    {" "}
                    <div className="mb-3 p-2">
                      <label className="form-label">Select Mobile Number</label>
                      <div className="">
                        <Select
                          options={mobile_no_arr}
                          value={{
                            label: optMobile?.label ? optMobile?.label : 'Select...',
                            value: optMobile?.value,
                          }}
                          onChange={(e) => setOptMobile({label:e.label, value: e.value})}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                {optMobile?.value === 'Add' &&
                  <>
                    <Row>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2">
                          <label className="form-label">Enter Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            onChange={(e) =>
                              onOtpFields("name", e.target.value)
                            }
                          />
                        </div>
                      </Col>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2">
                          <label className="form-label">Enter New Number</label>
                          <input
                            maxlength="10"
                            inputmode="numeric"
                            pattern="\d*"
                            type="text"
                            className="form-control"
                            required
                            onChange={(e) =>
                              onOtpFields("new_number", e.target.value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="mb-3 p-2">
                          <label className="form-label">Enter Email</label>
                          <input
                            type="email"
                            className="form-control"
                            required
                            value={email}
                            onChange={(e) =>
                              onOtpFields("email", e.target.value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    {(isAdd === false) &&
                      <div className="p-2">
                        <button className="btn btn-primary" onClick={handleAddNumber}>
                          Add
                        </button>
                      </div>
                    }
                  </>
                }
                {(optMobile?.value !== 'Add' || isAdd === true) &&
                  <>
                    <Row>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2">
                          <label className="form-label">Enter OTP</label>
                          <input
                            maxlength="6"
                            inputmode="numeric"
                            pattern="\d*"
                            type="text"
                            className="form-control"
                            required
                            onChange={(e) =>
                              onOtpFields("otp", e.target.value)
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="p-2 d-flex justify-content-between">
                      <button className="btn btn-primary  " type="submit">
                        Submit
                      </button>
                      <div className="btn btn-warning" onClick={handleAddNumber}>
                            Resend
                      </div>
                    </div>
                  </>
                }
              </form>
            </div>
          </div>
        </div>
        
      </Container>
    );
  }

};

export default EditCustomer;

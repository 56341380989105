import React, { useEffect, useState } from "react";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "../../../css/customer/customerTable.css";

import { useDispatch, useSelector } from "react-redux";
import { getAllAdmins } from "./../../../store/actionCreators/User/UserAction";
import Loader from "../../Loader/LoaderTable";
import axios from "axios";
import OtpVerification from "./OtpVerification";
import { toast } from "react-toastify";

const SuperAdmin = () => {

  const [allAdmins, setAllAdmins] = useState([]);
  const [adminId, setAdminId] = useState("");

  useEffect(() => {
    setDefaultData();
  }, []);

  const setDefaultData = async() => {
    
    try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}api/v1/admin/customer/fetch_admin_number`
        );
  
        if (res.data.status === "success") {
            setAllAdmins(res?.data?.data);
        }
      } catch (error) {
        console.log("error", error);
      }
  };

  useEffect(() => {
    setDefaultData();
  }, []);

  const handleDeleteNumber = async()=>{
    if(true){
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/admin/customer/admin_auth_otp`
        );

        if (res.data.status === 'success') {
          toast.success(
            `OTP sent successfully to Super Admin.`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
        else{
          toast.error(
            `Failed to send the OTP`,
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    return (
      <div
        style={{
          textAlign: "center",
          cursor: "pointer",
          lineHeight: "normal",
        }}
      >
        <Link
          exact="true"
          to={`/user/viewSuperadmin/${row.admin_verification_mobile_id}`}
          className="btn btn-sm btn-info"
        >
          View
        </Link>
        <Link
          exact="true"
          to={`/user/editSuperadmin/${row.admin_verification_mobile_id}`}
          style={{ marginLeft: "1rem" }}
          className="btn btn-sm btn-warning"
        >
          Edit
        </Link>
        <Button
          style={{ marginLeft: "1rem" }}
          className="btn btn-sm btn-danger"
          onClick={()=>{setAdminId(row.admin_verification_mobile_id)}}
        >
          Delete
        </Button>
      </div>
    );
  }
  async function handleSubmit(event) {
    event.preventDefault();
  }

  const columns = [
    {
      dataField: "full_name",
      text: "Full Name",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: false,
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: allAdmins.length,
    custom: true,
  };

  const { SearchBar } = Search;

  if (adminId === "") {
    return (
      <React.Fragment>
        <div className="page-content ">
          <form onSubmit={handleSubmit}>
            <Row>
              <Col className="col-12">
                <Card>
                  <Card.Body>
                    <Card.Title className="h4 mb-2 ">
                      Super Admin Management{" "}
                      <Link
                        className="btn btn-warning mb-3"
                        style={{ marginLeft: "1rem", marginRight: "1rem" }}
                        to={"/user/addNewNumber"}
                      >
                        Add New Number
                      </Link>
                    </Card.Title>

                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="admin_verification_mobile_id"
                      columns={columns}
                      data={allAdmins.map((item) => ({
                        ...item,
                        
                      }))}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="admin_verification_mobile_id"
                          columns={columns}
                          data={allAdmins.map((item) => ({
                            ...item,
                          }))}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        srText=""
                                        {...toolkitProps.searchProps}
                                        onChange={(e) => {
                                          console.log(e);
                                        }}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="admin_verification_mobile_id"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </form>
        </div>
      </React.Fragment>
    );
  }else if(adminId !== ""){
    return <OtpVerification admin_verification_mobile_id={adminId} setAdminId={setAdminId}/>
  }
  else{
    return <Loader />
  };
};

export default SuperAdmin;

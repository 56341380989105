import React, { useEffect, useState } from "react";
import { Card, Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Loader from "../../Loader/LoaderTable";
import { getItemsUnits } from "../../../store/actionCreators/Inventory/InventoryAction";
import Unauthorized from "../../unauthorized";
import { toast } from "react-toastify";
import axios from "axios";

const AllUnitsList = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const allUnitList = useSelector((state) => state.get_items_units_list);

  const [loading, setLoading] = useState(true);
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    setLoading(true);
    dispatch(getItemsUnits());
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getItemsUnits());
  }, []);

  useEffect(() => {
    setLoading(false);
    setDefaultData();
  }, [allUnitList]);

  const setDefaultData = () => {
    let itemsList = [];
    if (allUnitList.data && allUnitList.data.data) {
      itemsList = allUnitList.data.data.map((c) => ({
        ...c,
        createdAt: c.createdAt.toLocaleString().split("T")[0],
      }));
    }
    setItemsList(itemsList);
  };

  const handleDelete = async (name) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}api/v1/admin/inventory/delete_unit`, {
        name,
      });
      dispatch(getItemsUnits())
      toast.success(response.data.message); 
    } catch (error) {
      console.error('Error deleting item:', error);
      const errorMessage = error.response?.data?.message || 'An error occurred while deleting the item';
      toast.error(errorMessage);
    }
  };

  function rankFormatter(cell, row, rowIndex, formatExtraData) {
    if (row.action === "search") return "";
    if (props.editPermission) {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() =>
              navigate(`view-units/${row.name}`, { state: { data: row } })
            }
            className="btn btn-sm btn-warning"
          >
            View
          </button>
          <button
            // exact="true"
            // to={`edit-units/${row.name}`}
            onClick={() =>
              navigate(`edit-units/${row.name}`, { state: { data: row } })
            }
            className="btn btn-sm btn-warning"
            style={{ marginLeft: "0.6rem" }}
          >
            Edit
          </button>
          <button
            // exact="true"
            // to={`edit-units/${row.name}`}
            onClick={() =>
              handleDelete(row.name)
            }
            className="btn btn-sm btn-danger"
            style={{ marginLeft: "0.6rem" }}
          >
            Delete
          </button>
        </div>
      );
    } else {
      return (
        <div
          style={{
            textAlign: "center",
            cursor: "pointer",
            lineHeight: "normal",
          }}
        >
          <button
            onClick={() =>
              navigate(`view-units/${row.name}`, { state: { data: row } })
            }
            className="btn btn-sm btn-warning"
          >
            View
          </button>
        </div>
      );
    }
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      sort: true,
    },

    {
      dataField: "view",
      text: "Actions",
      isDummyField: true,
      csvExport: false,
      formatter: rankFormatter,
      headerAlign: "center",
    },
  ];

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: allUnitList.data?.per_page,
    totalSize: allUnitList.data
      ? allUnitList.data.total_items
        ? allUnitList.data.total_items
        : 0
      : 0,
    custom: true,
  };

  const getAllRows = () => {
    const allUnitList = [...itemsList];
    return allUnitList;
  };

  const { SearchBar } = Search;

  if (props.viewPermission) {
    if (!loading) {
      return (
        <React.Fragment>
          <Container
            fluid
            className={props.sideToggle === true ? "closeDash" : "openDash"}
            style={{ paddingTop: "95px", backgroundColor: "#F1F5F7" }}
          >
            <Col lg={8} sm={4} xs={12} className="inventory-dash-head">
              Inventory Item's Unit List{" "}
            </Col>
            <Col lg={4} sm={8} xs={12}></Col>
            <div className="page-content ">
              <Row>
                <Col className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="d-flex justify-content-between mb-2">
                        <div
                          className="d-flex justify-content-between "
                          style={{ gap: "12px" }}
                        >
                          <Card.Title>All Units </Card.Title>
                        </div>

                        <Link
                          className="btn btn-warning"
                          to={"/inventory/addunits"}
                        >
                          Add New Unit
                        </Link>
                      </div>

                      {
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="inventory_items_id"
                          columns={columns}
                          data={getAllRows()}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="inventory_items_id"
                              columns={columns}
                              data={getAllRows()}
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="search-box chat-search-box" />
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="inventory_items_id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </React.Fragment>
      );
    } else return <Loader />;
  } else {
    return <Unauthorized />;
  }
};

export default AllUnitsList;

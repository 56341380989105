import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Dropdown,
    DropdownButton,
    Button,
} from "react-bootstrap";
import axios from 'axios';
import { toast } from 'react-toastify';

function OtpVerification({ admin_verification_mobile_id, setAdminId }) {
    const navigate = useNavigate();
    const [otp, setOtp] = useState("")

    const handleOtpSubmit = async (e) => {
        e.preventDefault()
        try {
            const res = await axios.post(
                `${process.env.REACT_APP_API_URL}api/v1/admin/customer/delete_super_admin`, {
                otp: otp,
                admin_verification_mobile_id
            }
            );

            if (res.data.status === 'success') {
                toast.success(
                    `Admin details deleted successfully.`,
                    {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
                setTimeout(() => {
                    window.location.reload();
                    setAdminId("");
                }, 3100);
            } else {
                toast.error(
                    `${res.data.message}`,
                    {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    }
                );
            }
        } catch (error) {
            console.log("error", error);
        }
    }

    const handleDeleteNumber = async () => {
        if (admin_verification_mobile_id != "") {
            try {
                const res = await axios.post(
                    `${process.env.REACT_APP_API_URL}api/v1/admin/customer/admin_auth_otp`
                );

                if (res.data.status === 'success') {
                    toast.success(
                        `OTP sent successfully to Super Admin.`,
                        {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        }
                    );
                    setTimeout(() => {
                        navigate(`/user/superadmin`);
                    }, 3100);
                }
                else {
                    toast.error(
                        `Failed to send the OTP`,
                        {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        }
                    );
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    useEffect(() => {
        handleDeleteNumber()
    }, [])

    const onOtpFields = (field_name, value) => {
        if (field_name === 'otp') {
            setOtp(value);
        }

    };

    return (
        <Container
            fluid
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "70vh",
                paddingTop: "0px",
                backgroundColor: "#F1F5F7",
            }}
        >
            <div className="row d-flex justify-content-center">
                <div className="form-container">
                    <div className="form-head d-flex">
                        <div>OTP Verification</div>
                        <div className="ml-auto" style={{ marginLeft: "auto" }}>
                            <Button
                                onClick={() => {
                                    setAdminId("");
                                }}
                                type="primary"
                            >
                                Back
                            </Button>
                        </div>
                    </div>
                    <div className="form-body">
                        <form onSubmit={handleOtpSubmit}>
                            <Row>
                                <Col>
                                    {" "}
                                    <div className="mb-3 p-2">
                                        <label className="form-label">Enter OTP</label>
                                        <input
                                            maxlength="6"
                                            inputmode="numeric"
                                            pattern="\d*"
                                            type="text"
                                            className="form-control"
                                            required
                                            onChange={(e) =>
                                                onOtpFields("otp", e.target.value)
                                            }
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div className="p-2 d-flex justify-content-between">
                                <button className="btn btn-primary  " type="submit">
                                    Submit
                                </button>
                                <div className='btn btn-warning' onClick={handleDeleteNumber}>
                                    Resend
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </Container>
    )
}

export default OtpVerification
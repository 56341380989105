import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Col,
  Container,
  Row,
  Button,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

const ViewSuperAdmin = (props) => {
  const navigate = useNavigate()

  const [state, setState] = useState({
    full_name: "",
    phone: "",
    email:""
  });

  const params = useParams();
  const setDefaultData = async() => {
    try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}api/v1/admin/customer/fetch_single_super_admin`,{
            admin_verification_mobile_id:params.admin_verification_mobile_id
          }
        );
  
        if (res.data.status === "success") {
            setState({
                full_name: res.data.data.full_name || "",
                phone: res.data.data.phone || "",
                email: res.data.data.email || ""
            });
        }
      } catch (error) {
        console.log("error", error);
      }
  };
  
  useEffect(() => {
    setDefaultData()
  }, []);

  const main = () => {
    return (
        <Container
        fluid
        className={props.sideToggle === true ? "closeDash" : "openDash"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          paddingTop: "95px",
          backgroundColor: "#F1F5F7",
        }}
      >
        <div className="row d-flex justify-content-center">
          <div className="form-container">
            <div className="form-head d-flex">
              <div>Super Admin Detail</div>
              <div className="ml-auto" style={{ marginLeft: "auto" }}>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                  type="primary"
                >
                  Back
                </Button>
              </div>
            </div>
            <div className="form-body">
                {
                  <>
                    <Row>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={state.full_name}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2">
                          <label className="form-label">Number</label>
                          <input
                            type="text"
                            className="form-control"
                            required
                            value={state.phone}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <div className="mb-3 p-2">
                          <label className="form-label">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            required
                            value={state.email}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                }
            </div>
          </div>
        </div>
        
      </Container>
    );
  };

  return <div>{main()}</div>;
};

export default ViewSuperAdmin;
